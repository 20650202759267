import * as React from 'react';
import { BiLogger } from '../../routes/bi';
import { useFedopsLogger as getFedopsLogger } from '../../utils/fedops-logger';
import { NavLinkJsx } from './nav-link-jsx';
import { useNavLinksBi } from './bi';

export const ArticleNavLink = ({ model, biLogger }: { model: any, biLogger: BiLogger }) => (props) => {
  const { id: articleId, children, articleUri, isCustomerPortal, searchResultsIdx, searchTerm } = props;
  const {
    articles = [],
    knownIssues = [],
    featureRequests = [],
    relatedArticles = [],
    featuredArticles = [],
  } = model;
  const article =
    articles.find((e) => e.id === articleId) ||
    knownIssues.find((e) => e.id === articleId) ||
    featureRequests.find((e) => e.id === articleId) ||
    relatedArticles.find((e) => e.id === articleId) ||
    featuredArticles.find((e) => e.id === articleId);
  const uri = `${(articleUri || article?.uri || '')}`;
  const fedopsLogger = getFedopsLogger();
  const navLinksBi = useNavLinksBi(biLogger, articleId);
  const startNavigateToArticleInteraction = () => {
    fedopsLogger.startInteraction('article_navigation');

    if (isCustomerPortal) {
      navLinksBi.sendPublicCustomerPortalViewerClientEngagement(model.tenant.brandId);
    } else if (!!searchTerm) {
      navLinksBi.sendSearchResultsClick(searchTerm, searchResultsIdx);
    }
  };
  return (
    <NavLinkJsx uri={uri} onClick={startNavigateToArticleInteraction}>
      {children}
    </NavLinkJsx>
  );
};
